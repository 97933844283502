import React from 'react';
import { skills } from '../../Data';
import "./style.scss";
import shapeImgOne from "../../assets/images/shape-1.webp";

const Skills = () => {
    return (
        <>
            <section className="skills section" id='skills'>
                <h2 className="section__title text-cs">Professional Skills</h2>
                <p className="section__subtitle">
                    My <span>Talent</span>
                </p>

                <div className="skills__container container  grid">
                    {skills.map(({ name, percentage, description }, index) => {
                        return (
                            <div className="skills__item" key={index}>
                                <div className="skills__titles">
                                    <span className="skills__name">{name}</span>
                                    <h3 className="skills__number">
                                        {percentage} <span>%</span>
                                    </h3>
                                </div>
                                <p className="skills__description">{description}</p>
                                <div className="skills__bar">
                                    <span className="skills__percentage" style={{ width: `${percentage}%` }}>
                                        <span></span>
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className="section__deco deco__left">
                    <img width="100%" 
              height="100%" loading='lazy' src={shapeImgOne} alt="shapeImg_skill1" className='shape' />
                </div>

                <div className="section__bg-wrapper">
                    <span className="bg__title">Skills</span>
                </div>
            </section>
        </>
    )
}

export default Skills;