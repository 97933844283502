import React from 'react';

interface ColorSwitcherItemProps {
  color: string;
  img: string;
  changeColor: (color: string) => void;
}

const ColorSwitcherItem: React.FC<ColorSwitcherItemProps> = ({ color, img, changeColor }) => {
  return (
    <>
      <img 
        loading='lazy'
        src={img}
        width="100%"
        height="100%"
        alt={`${color}color`}
        className="color__img"
        onClick={() => changeColor(color)}
      />
    </>
  );
};

export default ColorSwitcherItem;